<template>
  <div
    class="studio-sidebar"
    :style="cssProps"
  >
    <StudioCategoryDropdown
      :resource="resource"
      class="studio-sidebar__category-dropdown"
    />

    <component
      :is="$options.RESOURCE_NAVIGATION_COMPONENTS[resource]"
      v-bind="$attrs"
      class="studio-sidebar__navigation"
      @resources-changed="updateResourcePanelHeight"
    />

    <ResourcePanel
      ref="resourcePanel"
      :resource="resource"
    >
      <template
        v-if="$options.RESOURCE_ITEMS_COMPONENTS[resource]"
        #resource-items
      >
        <component :is="$options.RESOURCE_ITEMS_COMPONENTS[resource].name" />
      </template>
    </ResourcePanel>
  </div>
</template>
<script>
import ResourcePanel from './ResourcePanel';
import StudioCategoryDropdown from './CategoryDropdown';

const RESOURCE_ITEMS_COMPONENTS = Object.freeze({
  view: null,
  sheet: null,
  workflow: {
    name: 'ResourcePanelWorkflowItems',
    path: 'ResourcePanel/_workflow-items',
  },
});

const RESOURCE_NAVIGATION_COMPONENTS = Object.freeze({
  view: 'ViewsNavigation',
  sheet: 'DataNavigation',
  workflow: 'WorkflowsNavigation',
});

export default {
  name: 'StudioSidebar',
  RESOURCE_NAVIGATION_COMPONENTS,
  RESOURCE_ITEMS_COMPONENTS,
  components: {
    StudioCategoryDropdown,
    ResourcePanel,
    ResourcePanelWorkflowItems: defineAsyncComponent(() => import('~/components/studio/Sidebar/ResourcePanel/_workflow-items')),
    ViewsNavigation: defineAsyncComponent(() => import('~/components/studio/Sidebar/ViewsNavigation')),
    DataNavigation: defineAsyncComponent(() => import('~/components/studio/Sidebar/DataNavigation')),
    WorkflowsNavigation: defineAsyncComponent(() => import('~/components/studio/Sidebar/WorkflowsNavigation')),
  },
  props: {
    resource: {
      type: String,
      required: true,
      validator: value => ['view', 'sheet', 'workflow'].includes(value),
    },
  },
  data() {
    return {
      resourcePanelHeight: 0,
    };
  },
  computed: {
    cssProps() {
      return {
        '--resource-panel-height': `${this.resourcePanelHeight}px`,
      };
    },
  },
  async mounted() {
    await nextTick();
    this.updateResourcePanelHeight();
  },
  async updated() {
    await nextTick();
    this.updateResourcePanelHeight();
  },
  methods: {
    updateResourcePanelHeight() {
      this.resourcePanelHeight = this.$refs?.resourcePanel?.$el?.getBoundingClientRect()?.height;
    },
  },
};
</script>

<style lang="scss">
$category-dropdown-height: 32px;
$category-dropdown-margin: 8px;
$resource-panel-spacing: 8px;

.studio-sidebar {
  background-color: var(--z-theme-surface);
  color: var(--z-theme-text);
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.studio-sidebar__category-dropdown {
  margin: $category-dropdown-margin;
}

.studio-sidebar__navigation {
  flex: 1 1 0;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
