import ROUTES from '~/assets/javascript/constants/routes';

export const useCurrentCategory = () => {
  const { $store, $localeRoute, $router } = useNuxtApp();
  const route = useRoute();
  const { tenantSlug } = route.params;

  function redirectTo(route) {
    $router.push($localeRoute(route));
  }

  function redirectToViewShowRoute(viewRoute, viewId) {
    redirectTo({
      name: viewRoute.routeName,
      params: { tenantSlug, [viewRoute.viewId]: viewId },
    });
  }

  async function changeCategory(categoryName: string, {
    firstViewRedirect = false,
    firstWorkflowRedirect = false,
    firstSheetRedirect = false,
    studio = false,
  } = {}) {
    $store.commit('workspace/setCurrentCategoryName', categoryName);

    if (firstViewRedirect) {
      if (studio) {
        const studioViews = $store.state.builderView.views;

        $store.dispatch('workspace/firstViewIdFromViews', studioViews).then((firstViewId) => {
          if (firstViewId) {
            redirectToViewShowRoute(ROUTES.studio.view, firstViewId);
          } else {
            redirectTo({
              name: ROUTES.studio.views.routeName,
              params: { tenantSlug },
            });
          }
        });
      } else {
        const viewId = $store.getters['workspace/firstScopedViewId'];

        redirectToViewShowRoute(ROUTES.member.view, viewId);
      }
    }

    if (firstWorkflowRedirect) {
      const firstWorkflowId = $store.getters['workspace/firstWorkflowId'];

      if (firstWorkflowId) {
        redirectTo({
          name: ROUTES.studio.workflow.routeName,
          params: { tenantSlug, id: firstWorkflowId },
        });
      } else {
        redirectTo({
          name: ROUTES.studio.workflows.routeName,
          params: { tenantSlug },
        });
      }
    }

    if (firstSheetRedirect) {
      const firstSheetId = $store.getters['workspace/firstSheetId'];

      if (firstSheetId) {
        redirectTo({
          name: ROUTES.studio.sheet.routeName,
          params: { tenantSlug, id: firstSheetId },
        });
      } else {
        redirectTo({
          name: ROUTES.studio.sheets.routeName,
          params: { tenantSlug },
        });
      }
    }
  }

  return {
    changeCategory,
  };
};
