<template>
  <div>
    <div class="resources-panel">
      <slot
        name="resource-items"
      />

      <Archive :resource="resource" />
    </div>
  </div>
</template>

<script>
import Archive from './_archive';

export default {
  name: 'ResourcePanel',
  components: {
    Archive,
  },
  props: {
    resource: {
      type: String,
      required: true,
      validator: value => ['view', 'sheet', 'workflow'].includes(value),
    },
  },
};
</script>
<style lang="scss">
.resources-panel {
  border-top: 1px solid var(--z-theme-background-secondary);
  padding: var(--z-s1);
}
</style>
