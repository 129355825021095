<i18n lang="yaml">
pt:
  editCategories: 'Editar Apps'
  createCategory: 'Criar App'
  collapseCategoriesMenu: 'Recolher menu de Apps'
  expandCategoriesMenu: 'Expandir menu de Apps'
  select: 'Selecionar'
en:
  editCategories: 'Edit Apps'
  createCategory: 'Create App'
  collapseCategoriesMenu: 'Collapse Apps menu'
  expandCategoriesMenu: 'Expand Apps menu'
  select: 'Select'
</i18n>

<template>
  <div class="studio-category-dropdown">
    <v-menu
      location="bottom"
      :max-width="230"
      :max-height="500"
    >
      <template #activator="{ props, isActive }">
        <v-chip
          v-bind="props"
          class="studio-category-dropdown__chip align-center v-chip--block d-flex"
          variant="outlined"
          label
          :aria-label="isActive ? t('collapseCategoriesMenu') : t('expandCategoriesMenu')"
        >
          <v-icon
            class="mr-2 ml-0"
            size="small"
            start
          >
            {{ currentCategory.category_icon }}
          </v-icon>

          <span class="text-truncate d-flex">
            {{ currentCategory.category_name }}
          </span>

          <v-spacer />

          <deck-button
            v-if="currentCategoryName && !currentCategory.locked"
            size="small"
            kind="ghost"
            color="controls"
            icon="pen"
            @click.stop="openDialogEditCategory"
          />

          <v-icon
            end
            size="small"
            :title="isActive ? t('collapseCategoriesMenu') : t('expandCategoriesMenu')"
            :aria-label="isActive ? t('collapseCategoriesMenu') : t('expandCategoriesMenu')"
          >
            far {{ isActive ? 'fa-caret-up' : 'fa-caret-down' }}
          </v-icon>
        </v-chip>
      </template>

      <v-list density="compact">
        <v-list
          v-if="modifiedCategoryHierarchy.length"
          max-height="280px"
          :selected="currentCategoryName"
          density="compact"
          @update:selected="selectedCategoryChanged($event[0])"
        >
          <v-list-item
            v-for="({ category_icon, category_name, locked }, index) in modifiedCategoryHierarchy"
            :key="index"
            :value="category_name"
            :active="category_name === currentCategoryName"
            :aria-label="`${t('select')} ${category_name}`"
            density="compact"
          >
            <template #prepend>
              <v-icon
                start
                size="small"
              >
                {{ category_icon }}
              </v-icon>
            </template>

            <div
              class="text-body-2 text-truncate"
              :title="category_name"
            >
              {{ category_name }}
            </div>

            <template #append>
              <deck-chip
                v-if="locked"
                class="ml-auto"
                icon="lock"
                size="small"
                color="controls"
              />
            </template>
          </v-list-item>
        </v-list>

        <v-divider  />

        <v-list-item
          density="compact"
          @click="openDialogEditCategories"
        >
          <template #prepend>
            <v-icon
              start
              size="small"
            >
              fa-pen fa-regular
            </v-icon>
          </template>

          <div
            class="text-body-2 text-truncate"
            :title="t('editCategories')"
          >
            {{ t('editCategories') }}
          </div>
        </v-list-item>

        <v-list-item
          density="compact"
          @click="openDialogCategoryCreation"
        >
          <template #prepend>
            <v-icon
              start
              size="small"
            >
              fa-plus fa-regular
            </v-icon>
          </template>

          <div
            class="text-body-2 text-truncate"
            :title="t('createCategory')"
          >
            {{ t('createCategory') }}
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from '~/assets/javascript/modules/vuex';
import DeckChip from '~/deck/chip';

export default {
  name: 'StudioCategoryDropdown',
  components: { DeckChip },
  props: {
    resource: { type: String, required: true, validator: value => ['view', 'sheet', 'workflow'].includes(value) },
  },
  setup() {
    const { changeCategory } = useCurrentCategory();
    return {
      t: useI18n().t,
      ...mapState('workspace', ['currentCategoryName']),
      ...mapGetters('workspace', ['categoryHierarchy']),
      ...mapGetters('workspace', ['categoryHierarchy']),
      ...mapMutations('dialog', ['openDialog']),
      changeCategory,
    };
  },
  computed: {
    modifiedCategoryHierarchy() {
      if (this.resource === 'sheet') return this.categoryHierarchy;

      return this.categoryHierarchy.filter(category => category.category_name !== 'Bases compartilhadas');
    },
    currentCategory() {
      if ((this.modifiedCategoryHierarchy || []).length === 0) return {};

      return this.modifiedCategoryHierarchy.find(({ category_name: name }) => name === this.currentCategoryName) || {};
    },
  },
  methods: {
    async selectedCategoryChanged(categoryName) {
      this.changeCategory(categoryName, {
        firstViewRedirect: this.resource === 'view',
        firstWorkflowRedirect: this.resource === 'workflow',
        firstSheetRedirect: this.resource === 'sheet',
        studio: true,
      });
    },
    openDialogCategoryCreation() {
      this.openDialog({
        component: 'DialogCreateOrEditCategory',
        attrs: {},
        on: {
          onCreate: (categoryName) => {
            this.selectedCategoryChanged(categoryName);
          },
        },
      });
    },
    openDialogEditCategories() {
      this.openDialog({
        component: 'DialogEditCategories',
        attrs: {},
      });
    },
    openDialogEditCategory() {
      this.openDialog({
        component: 'DialogCreateOrEditCategory',
        attrs: {
          categoryName: this.currentCategory.category_name,
          categoryIcon: this.currentCategory.category_icon,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.studio-category-dropdown {
  z-index: 102;

  & .v-chip__content {
    width: 100%;
  }
}

.studio-category-dropdown__chip {
  border-radius: var(--z-border-radius-inner-base);
}
</style>
